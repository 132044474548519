(function () {
    var loadTeasers = function () {

        var teaserFlag = false;

        var checkProgress = function () {

            $('[data-more]').each(function () {

                if (($(window).scrollTop() + $(window).height() + 200) >= $(document).height()) {
                    if (!teaserFlag && $('.teaser-load').last().data('more') !== undefined) {
                        $.ajax({
                            url: $('[data-more]').last().data('more'),
                            beforeSend: function () {
                                teaserFlag = true;
                            },
                            error: function () {
                            },
                            success: function (html) {

                                $(html)
                                    .insertAfter($('[data-more]').last());

                                teaserFlag = false;
                                if (!$('.teaser-load').last().data('more')) {
                                    return;
                                }
                            }

                        });
                    }
                }

            });
            setTimeout(checkProgress, 250);
        };

        setTimeout(checkProgress, 500);
    };
    if ($('.teaser-load').length) {
        loadTeasers();
    }

})();
