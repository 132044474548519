//ver 1.1 GT

'use strict';

function sendStats(category, action, label, value) {
  if (value) {
    WP.stat.show(
      wp_sn,
      {
        category: category,
        label: label,
        value: value,
        asproduct: asproduct,
        asorderid: asorderid,
        asname: asname,
      },
      action
    );
    gtag('event', action + '-' + value, {
      event_category: category,
      event_label: label,
    });
    console.log(
      'sendStats ' +
        category +
        ', ' +
        action +
        ', ' +
        label +
        ', ' +
        value +
        ', asproduct:' +
        asproduct +
        ', asorderid: ' +
        asorderid +
        ', asname: ' +
        asname
    );
  } else {
    WP.stat.show(
      wp_sn,
      {
        category: category,
        label: label,
        asproduct: asproduct,
        asorderid: asorderid,
        asname: asname,
      },
      action
    );
    gtag('event', action, {
      event_category: category,
      event_label: label,
    });
    console.log(
      'sendStats ' +
        category +
        ', ' +
        action +
        ', ' +
        label +
        ', asproduct:' +
        asproduct +
        ', asorderid: ' +
        asorderid +
        ', asname: ' +
        asname
    );
  }
}

var custom360Action = function (j) {
  WP.pwp.customAction({
    ga: j,
  });
};

$('[data-ga360-click]').on('click auxclick contextmenu', function (e) {
  if (e.type == 'contextmenu') {
    return;
  }

  var T = $(this).data('ga360-click');
  switch (e.which) {
    case 1:
      //console.log('[data-ga360-click] = click');
      WP.pwp.customAction({
        ga: T,
      });
      break;
    case 2:
      //console.log('[data-ga360-click] = weel click');
      WP.pwp.customAction({
        ga: T,
      });
      break;
    case 3:
      return true;
  }
});

var scroll100Indicator = 0;
var unloadCollector = new Array(); // { id0, viewedCount0, itemCount0 }, { id1, viewedCount1, itemCount1 }  tablica do inteaktuwności sliderowych.
var forUnloadCollector = function (id, viewedCount, itemCount) {
  var objectData = new Object();
  objectData.id = id;
  objectData.viewedCount = viewedCount;
  objectData.itemCount = itemCount;
  unloadCollector.push(objectData);
};
