// $(document).ready(function () {
//
//     $('.gallery-item').each(function () {
//         var elem = $(this);
//         var color = elem.attr('data-color');
//         elem.find('.item-text h4').each(function () {
//             $(this).css({backgroundColor: color});
//         });
//         elem.find('.splash-foto').css({fill: color});
//         elem.find('.orbit .splash-path').css({fill: color});
//         elem.find('.zigzag').css({fill: color});
//         elem.find('.zigzag-path').css({stroke: color});
//         elem.find('.waves-path').css({stroke: color});
//         elem.find('.wave-splash .wave-splash-path').css({fill: color});
//     });
// });

const mainColorElements = [...document.querySelectorAll('.gallery-item')];

const changeElementProperty = (elements, propertyName,dataAttrName) => {
    elements.forEach((el) => {
        const property = el.getAttribute(dataAttrName);
        el.style.setProperty(propertyName, property);
    });
};
changeElementProperty(mainColorElements, "--main-color",'data-color');
