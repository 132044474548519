$(".section-head").mousemove(function (event) {

    var mousex = event.pageX - $(this).offset().left;
    var mousey = event.pageY - $(this).offset().top;

    var imgx = (mousex - 100) / 100;
    var imgy = (mousey - 100) / 100;

    $('.dots').css({transform: "translate(" + imgx + "px," + imgy + "px)"});
    $('.dots2').css({transform: "translate(" + imgy + "px," + imgx + "px)"});
    $('.collage-zigzag').find('.gallery-bg').css({transform: "translate(" + imgx + "px," + imgy + "px)"});
    $('.collage-waves').find('.gallery-bg').css({transform: "translate(" + imgx + "px," + imgy + "px)"});
    // animationTime = imgx / 10;
    animationTime = 1;
    animationZigZag = 0.35;

});

$(".section-head").mouseleave(function () {
    animationTime = 0;
    animationZigZag = 0;
});

// ZIGZAG

var animationTime = 0;
var animationZigZag = 0;

$('.collage-zigzag').each(function () {

    var elem = $(this);
    var xs = [];

    for (var i = 0; i < 500; i++) {
        xs.push(i * 21);
    }

    var t = 0;

    function animate() {
        var animationSpeed = animationZigZag;
        var points = xs.map(function (x) {
            if (x % 2 === 0) {
                x = x - t;
                var y = 0;
            } else {
                x = x - t;
                var y = 16;
            }
            return [x, y];
        });
        var path = "M" + points.map(function (p) {
            return p[0] + ',' + p[1];
        }).join(" L");

        $(elem).find(".zigzag-path").attr("d", path);
        t += animationSpeed;
        requestAnimationFrame(animate);
    }

    animate();
});

$('.collage-waves').each(function () {

    var elem = $(this);
    var xs = [];

    for (var i = 0; i < 500; i++) {
        xs.push(i);
    }

    var t = 0;

    function animateWaves() {
        var animationSpeed = animationTime;
        var points = xs.map(function (x) {
            var y = 50 + 15 * Math.cos((x + t) / 15);
            return [x, y];
        });
        var path = "M" + points.map(function (p) {
            return p[0] + ',' + p[1];
        }).join(" L");

        $(elem).find(".waves-path").attr("d", path);
        t += animationSpeed;
        requestAnimationFrame(animateWaves);
    }

    animateWaves();
});

//Stripes Parallax
const element = document.querySelector('.section-head');

const stripesParallax = (e) => {
    const x = ( e.pageX-100) / 100;
    const y = ( e.pageY-100) / 100;
    element.style.setProperty("--translated-position", `translate(${x}px, ${y}px)`);
};

element.addEventListener('mousemove', stripesParallax);
