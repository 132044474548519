var sliderHeader = $(".owl-carousel");

sliderHeader.owlCarousel({
    loop: true,
    margin: 1,
    nav: true,
    center: true,
    items: 1,
    slideTransition: 'ease-in-out',
    smartSpeed: 450
});
